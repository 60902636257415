<template>
  <div
    class="row fade-left-element"
    v-bind:class="{ 'fade-left-element animate__animated animate__fadeInLeft ': isVisible }"
    ref="elementToObserve"
  >
    <div class="col-sm-12 col-md-12 col-lg-7 my-auto">
      <div class="slide-title w-full lg:w-5/6 font-bold">Empowering food delivery tech.</div>
      <div class="slide-logo w-1/4 bg-white">
        <!-- <img src="../../assets/images/Nutrifir-logo.svg" class="w-100 px-1" alt /> -->
      </div>
      <div
        class="slide-description sm:w-4/6 w-3/4 mt-5"
      >Magure is working closely with 'Nutrifit' to cater all there technological needs.</div>
      <div class="slide-left-button text-left md:mt-15 mt-11">
        <base-button
          :href="'/contact-us'"
          id="GetInTouchThree"
          class="btn-primary"
          v-bind:state="buttonState"
          aria-expanded="false"
        >Talk Now</base-button>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-5 text-center d-none d-lg-block d-xl-block">
      <div class="title-image ml-auto flex content-end">
        <img src="../../assets/images/nutrifit-side-image.webp" alt />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../BaseButton";
import ButtonState from "../../lib/button-state";

export default {
  components: {
    BaseButton
  },
  data() {
    return {
      buttonState: ButtonState.Ready,
      isVisible: false
    };
  },

  mounted() {
    const options = {
      rootMargin: "0px",
      threshold: 0.5 // Adjust this threshold value as needed
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isVisible = true;
        }
      });
    }, options);

    observer.observe(this.$refs.elementToObserve);
  }
};
</script>

<style lang="scss" scoped>
// @import "../../styles/components/Home/SlideOne.scss"
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
